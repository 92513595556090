import React, { useEffect, useState } from 'react';
import { EventInput } from '@fullcalendar/core';
import EditBookedLessonTeacherCalendar from './calendar/EditBookedLessonTeacherCalendar';
import { ArrowBigRight, Calendar, Clock, MapPin, CheckCircle, ArrowLeft } from 'lucide-react';
import api from '../utils/api';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';
import { motion } from 'framer-motion';

interface EditBookedLessonTeacherProps {
  event: EventInput;
  closeModal: () => void;
}

type step = 'Calendar' | 'Confirm';

const EditBookedLessonTeacher: React.FC<EditBookedLessonTeacherProps> = ({ event, closeModal }) => {
  useEffect(() => {
    console.log(event);
  }, [event]);

  const dateStart = event.start as Date;
  console.log(dateStart);
  const dateEnd = event.end as Date;
  const [currentStep, setCurrentStep] = useState<step>('Calendar');
  const duration = (dateEnd.getTime() - dateStart.getTime()) / (1000 * 60 * 60);
  const [BookedLessonData, setBookedLessonData] = useState<EventInput>({
    id: event.id,
    location: event.extendedProps.location,
    start: dateStart,
    end: dateEnd,
    student_id: event.extendedProps.student.id,
    teacher_id: event.extendedProps.teacher.id,
    size: duration,
    subject_id: event.extendedProps.subject.id,
  });
  const { setLoading } = useLoading();
  const { setError } = useError();
  useEffect(() => {
    console.log('Dati della lezione:', BookedLessonData);
  }, [BookedLessonData]);

  const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
  const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };

  const handleSlotClick = (start: Date, end: Date) => {
    console.log('Cliccato:');
    setBookedLessonData((prev) => ({
      ...prev,
      start,
      end,
    }));
    setCurrentStep('Confirm');
  };

  const formatDateTime = (date: Date): string => {
    const pad = (n: number) => (n < 10 ? `0${n}` : n);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(
      date.getMinutes()
    )}:${pad(date.getSeconds())}`;
  };

  const confirmLessonUpdate = async () => {
    closeModal();
    setLoading(true);
    const formattedStart = formatDateTime(BookedLessonData.start as Date);
    try {
      const payload = {
        start_date_time: formattedStart,
        location: BookedLessonData.location,
        subject_id: BookedLessonData.subject_id,
        teacher_id: BookedLessonData.teacher_id,
        student_id: BookedLessonData.student_id,
        size: BookedLessonData.size,
      };
      console.log('payload per la modifica:', payload);
      const response = await api.put(`lesson/single/${event.id}`, payload);
      console.log('Lesson updated successfully:', response.data);
    } catch (error) {
      console.error(error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status);
    } finally {
      setLoading(false);
      //window.location.reload();
    }
  };

  return (
    <div className="flex flex-col gap-y-4 h-full">
      {currentStep === 'Calendar' && (
        <>
          <div className="bg-fpcwhite p-2 flex flex-col rounded-lg w-full shadow-md">
            <p>
              Studente: {event.extendedProps.student.first_name} {event.extendedProps.student.last_name}
            </p>
            <p>Materia: {event.extendedProps.subject.name}</p>
            <p>
              Giorno: {dateStart.toLocaleDateString('it-iT', dateOptions)} dalle{' '}
              {dateStart.toLocaleTimeString(undefined, timeOptions)} alle{' '}
              {dateEnd.toLocaleTimeString(undefined, timeOptions)}
            </p>
          </div>
          <div className="flex flex-col h-full">
            <EditBookedLessonTeacherCalendar event={BookedLessonData} handleSlotClick={handleSlotClick} />
          </div>
        </>
      )}
      {currentStep === 'Confirm' && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col gap-4 w-full">
          <div className="bg-fpcwhite p-6 rounded-lg shadow-lg w-full">
            <h2 className="text-xl font-bold text-fpcred mb-4 border-b pb-2">Conferma Modifica Lezione</h2>

            <div className="flex flex-col md:flex-row gap-6">
              {/* Informazioni originali */}
              <div className="flex-1 bg-gray-50 p-4 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold text-gray-700 mb-3">Lezione Attuale</h3>
                <div className="space-y-3">
                  <div className="flex items-center">
                    <Calendar className="mr-2 h-5 w-5 text-fpcred" />
                    <span className="text-gray-700">{dateStart.toLocaleDateString('it-IT', dateOptions)}</span>
                  </div>
                  <div className="flex items-center">
                    <Clock className="mr-2 h-5 w-5 text-fpcred" />
                    <span className="text-gray-700">
                      {dateStart.toLocaleTimeString(undefined, timeOptions)} -{' '}
                      {dateEnd.toLocaleTimeString(undefined, timeOptions)}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="mr-2 h-5 w-5 text-fpcred" />
                    <span className="text-gray-700">{event.extendedProps.location}</span>
                  </div>
                </div>
              </div>

              {/* Freccia di transizione */}
              <div className="flex items-center justify-center md:py-0 py-4">
                <ArrowBigRight size={32} className="text-fpcred transform transition-transform hover:scale-110 md:rotate-0 rotate-90" />
              </div>

              {/* Nuove informazioni */}
              <div className="flex-1 bg-fpcred bg-opacity-10 p-4 rounded-lg border-2 border-fpcred">
                <h3 className="text-lg font-semibold text-fpcred mb-3">Nuova Lezione</h3>
                <div className="space-y-3">
                  <div className="flex items-center">
                    <Calendar className="mr-2 h-5 w-5 text-fpcred" />
                    <span className="font-medium">
                      {(BookedLessonData.start as Date).toLocaleDateString('it-IT', dateOptions)}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Clock className="mr-2 h-5 w-5 text-fpcred" />
                    <span className="font-medium">
                      {(BookedLessonData.start as Date).toLocaleTimeString(undefined, timeOptions)} -{' '}
                      {(BookedLessonData.end as Date).toLocaleTimeString(undefined, timeOptions)}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="mr-2 h-5 w-5 text-fpcred" />
                    <span className="font-medium">{BookedLessonData.location}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-4 justify-between mt-6">
              <button
                onClick={() => setCurrentStep('Calendar')}
                className="flex items-center justify-center gap-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold rounded-lg w-1/3 p-3 transition-all duration-300 cursor-pointer">
                <ArrowLeft size={18} />
                Indietro
              </button>
              <button
                onClick={confirmLessonUpdate}
                className="flex items-center justify-center gap-2 bg-fpcred hover:bg-red-700 text-white font-bold rounded-lg w-1/3 p-3 transition-all duration-300 transform hover:scale-105 cursor-poiter">
                <CheckCircle size={18} />
                Conferma
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default EditBookedLessonTeacher;
