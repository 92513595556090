import React, { useEffect, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventClickArg, EventInput } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import api from '../utils/api';
import DaysSlider from '../components/DaySlider';
import { BookLessonData } from '../model/BookLessonData';
import useScreenWidth from '../hooks/useScreenWidth';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';
import '../styles/calendarStyles.css';

interface CalendarProps {
  bookLessonData: BookLessonData;
  onDateSelect: (date: DateSelectArg) => void;
}

// 1. Definisci la funzione di merging fuori dal componente (o in un file utility)
function mergeIntervals(intervals: { start: Date; end: Date }[]): { start: Date; end: Date }[] {
  if (!intervals.length) return [];

  // Ordina gli intervalli in base all'orario di inizio
  intervals.sort((a, b) => a.start.getTime() - b.start.getTime());

  const merged: { start: Date; end: Date }[] = [];
  let current = intervals[0];

  for (let i = 1; i < intervals.length; i++) {
    const interval = intervals[i];

    // Se l'intervallo successivo inizia prima o esattamente dove finisce current,
    // allora sono sovrapposti o adiacenti, quindi li fondiamo
    if (interval.start.getTime() <= current.end.getTime()) {
      current.end = new Date(Math.max(current.end.getTime(), interval.end.getTime()));
    } else {
      // Altrimenti chiudiamo l'intervallo corrente e ne apriamo uno nuovo
      merged.push(current);
      current = interval;
    }
  }
  // Aggiungiamo l'ultimo intervallo rimasto
  merged.push(current);

  return merged;
}

const MyCalendar: React.FC<CalendarProps> = ({ bookLessonData, onDateSelect }) => {
  const [availabilities, setAvailabilities] = useState<any[]>([]);
  const [events, setEvents] = useState<EventInput[]>([]);
  const [render, setRender] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const screenWidth = useScreenWidth();
  const { setLoading } = useLoading();
  const { setError } = useError();
  const calendarRef = useRef(null);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const [lessonsBooked, setLessonsBooked] = useState<any[]>([]);

  const today = new Date();
  const start = new Date(new Date().setDate(today.getDate() - 30));
  const maxDate = new Date(new Date().setDate(today.getDate() + 90));

  // Carica le lezioni prenotate (escludendo quelle cancellate o archiviate)
  useEffect(() => {
    api
      .get('/lesson')
      .then((response) => {
        const filteredLessons = (response.data?.lessons || []).filter((lesson) => {
          return lesson.status !== 'deleted' && lesson.status !== 'placeholder';
        });
        console.log('Lezioni originali:', response.data?.lessons);
        setLessonsBooked(filteredLessons);
        console.log('Lezioni filtrate:', filteredLessons);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .post('/availability/calendar', {
        modifying_lesson_id: bookLessonData.modifing_lesson_id,
        student_id: bookLessonData.child.id,
        teacher_id: bookLessonData.teacher ? bookLessonData.teacher.id : undefined,
        subject_id: bookLessonData.subject.id,
        searched_lesson_length: bookLessonData.duration,
        location: bookLessonData.location,
        n_weeks_visualized: 12,
      })
      .then((response) => {
        const availabilitiesData = response.data.availabilities;
        console.log('Book Lesson Data:', bookLessonData);
        console.log('Availabilities:', availabilitiesData);
        setAvailabilities(availabilitiesData);

        // Calcolo degli eventi di disponibilità
        const availableEvents = calculateAvailableTimes(availabilitiesData);
        console.log('availableEvents:', availableEvents);

        const filteredAvailabilitesEvents = availableEvents.filter((event) => {
          const dateStart = (event.start as Date).getTime();
          const dateEnd = (event.extendedProps.displayEnd as Date).getTime();

          lessonsBooked.some((lesson) => {
            // Se esiste una lezione in modifica ed è quella attuale, salta il confronto
            if (bookLessonData.modifing_lesson_id && lesson.id === bookLessonData.modifing_lesson_id) {
              return false;
            }
            const lessonStart = new Date(lesson.start_time).getTime();
            const lessonEnd = new Date(lesson.end_time).getTime();
            return dateStart < lessonEnd && dateEnd > lessonStart;
          });
          return true; // Rimuovi l'intervall
        });

        const unavailableEvents = calculateUnavailableTimes(filteredAvailabilitesEvents);
        console.log('unavailableEvents:', unavailableEvents);
        // Lezioni prenotate
        const bookedLessonsEvents = lessonsBooked.map((lesson) => {
          let startTime = new Date(lesson.start_time);
          let endTime = new Date(lesson.end_time);
          // Definiamo un array per le classi da usare nell'evento
          let classNames = ['cursor-not-allowed', 'bookedLesson'];

          // Se l'evento è quello in modifica, gestiamo in modo speciale
          if (bookLessonData.modifing_lesson_id && lesson.id === bookLessonData.modifing_lesson_id) {
            // Imposta la durata a 30 minuti

            if (startTime.getMinutes() % 30 === 0) {
              console.log('startTime multiplo di 30:', startTime);
              classNames.push('bookedLesson--modified');
              endTime = new Date(startTime.getTime() + 30 * 60000);
            } else if (startTime.getMinutes() % 15 === 0) {
              console.log('startTime multiplo di 15:', startTime);
              classNames.push('quarter-hour-modified');
              endTime = new Date(startTime.getTime() + 15 * 60000);
            }
          }

          return {
            id: `booked-${lesson.id}`,
            start: startTime,
            end: endTime,
            display: 'block',
            classNames, // utilizza l'array che abbiamo modificato
            editable: false,
            extendedProps: {
              eventType: 'booked',
            },
          };
        });

        // Unisci tutti gli eventi: slot disponibili, gli intervalli "unavailable" calcolati, lezioni prenotate e background unificati
        setEvents([...filteredAvailabilitesEvents, ...unavailableEvents, ...bookedLessonsEvents]);
        setRender(true);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [bookLessonData, lessonsBooked]);

  useEffect(() => {
    // Resetta l'evento selezionato quando la data cambia
    setSelectedEventId(null);
  }, [selectedDate]);

  // Calcola gli slot disponibili in base alle disponibilità
  const calculateAvailableTimes = (availabilities) => {
    const availableEvents: EventInput[] = [];
    const stepMs = 30 * 60000;
    const stepMs15 = 15 * 60000;
    const lessonDurationMs = bookLessonData.duration * 3600000;

    // Flag per tenere traccia se il controllo è già stato eseguito
    let quarterHourCheckDone = false;

    // Trova la lezione in modifica per escluderla dalle disponibilità
    const lessonInModifica = bookLessonData.modifing_lesson_id
      ? lessonsBooked.find((lesson) => lesson.id === bookLessonData.modifing_lesson_id)
      : null;

    availabilities.forEach((availability) => {
      const availableStart = new Date(availability.start_date_time).getTime();
      const availableEnd = new Date(availability.end_date_time).getTime();
      let currentTime = availableStart;

      while (currentTime < availableEnd) {
        const eventStart = new Date(currentTime);
        const displayEnd = new Date(currentTime + lessonDurationMs);
        const remainingTime = availableEnd - currentTime;

        // Verifica se questo slot si sovrappone con la lezione in modifica

        if (lessonInModifica) {
          const lessonStart = new Date(lessonInModifica.start_time).getTime();
          const lessonEnd = new Date(lessonInModifica.end_time).getTime();

          if (
            currentTime === lessonStart &&
            currentTime + lessonDurationMs === lessonEnd &&
            currentTime + lessonDurationMs === availableEnd
          ) {
            currentTime += availableEnd - lessonDurationMs;
            continue;
          }
          //   // Controlla se l'evento disponibile si sovrappone con la lezione in modifica
          if (currentTime === lessonStart && currentTime + lessonDurationMs === lessonEnd) {
            currentTime += stepMs;
            continue;
          }
          if (
            !quarterHourCheckDone &&
            currentTime - 15 * 60000 === lessonStart &&
            currentTime + lessonDurationMs - 15 * 60000 === lessonEnd
          ) {
            quarterHourCheckDone = true;
            // currentTime += stepMs15;
            continue;
          }
        }

        if (remainingTime === lessonDurationMs) {
          availableEvents.push({
            id: `${availability.id}-${currentTime}`,
            start: eventStart,
            end: new Date(availableEnd),
            display: 'block',
            classNames: ['cursor-pointer', 'bg-fpcred', 'bg-opacity-70', 'available-event'],
            editable: false,
            extendedProps: {
              displayStart: eventStart,
              displayEnd: new Date(availableEnd),
              eventType: 'available',
            },
          });
          currentTime += stepMs;
          break;
        } else if (remainingTime < lessonDurationMs) {
          availableEvents.push({
            id: `${availability.id}-${currentTime}`,
            start: eventStart,
            end: new Date(availableEnd),
            display: 'block',
            classNames: ['cursor-pointer', 'bg-fpcred', 'bg-opacity-70', 'available-event'],
            editable: false,
            extendedProps: {
              displayStart: new Date(availableEnd - lessonDurationMs),
              displayEnd: new Date(availableEnd),
              eventType: 'available',
            },
          });
          currentTime += stepMs;
          break;
        } else if (eventStart.getMinutes() % 30 === 0) {
          // Slot che inizia a un'ora esatta o alla mezz'ora
          availableEvents.push({
            id: `${availability.id}-${currentTime}`,
            start: eventStart,
            end: new Date(currentTime + stepMs),
            display: 'block',
            classNames: ['cursor-pointer', 'bg-fpcred', 'bg-opacity-70', 'available-event'],
            editable: false,
            extendedProps: {
              displayStart: eventStart,
              displayEnd: displayEnd,
              eventType: 'available',
            },
          });
          currentTime += stepMs;
        } else if (eventStart.getMinutes() % 15 === 0) {
          // Slot che inizia a un quarto d'ora
          availableEvents.push({
            id: `${availability.id}-${currentTime}`,
            start: eventStart,
            end: new Date(currentTime + stepMs15),
            display: 'block',
            classNames: ['cursor-pointer', 'bg-fpcred', 'bg-opacity-70', 'available-event', 'quarter-hour-slot'],
            editable: false,
            extendedProps: {
              displayStart: eventStart,
              displayEnd: displayEnd,
              eventType: 'available',
              isQuarterHour: true,
            },
          });
          currentTime += stepMs15;
        }
      }
    });

    return availableEvents;
  };

  // Calcola gli intervalli non disponibili (es. gap tra disponibilità)
  const calculateUnavailableTimes = (availabilityEvents) => {
    const unavailableEvents = [];
    const startRange = start.getTime();
    const endRange = maxDate.getTime();

    const sortedAvailabilities = [...availabilityEvents].sort((a, b) => a.start.getTime() - b.start.getTime());

    let lastEndTime = startRange;

    sortedAvailabilities.forEach((event) => {
      const availableStart = event.start.getTime();
      const availableEnd = event.extendedProps.displayEnd.getTime();

      if (lastEndTime < availableStart) {
        unavailableEvents.push({
          start: new Date(lastEndTime),
          end: new Date(availableStart),
          display: 'background',
          classNames: ['stripe-background', 'cursor-not-allowed', 'select-none'],
          editable: false,
          extendedProps: {
            eventType: 'unavailable',
          },
        });
      }

      if (availableEnd > lastEndTime) {
        lastEndTime = availableEnd;
      }
    });

    if (lastEndTime < endRange) {
      unavailableEvents.push({
        start: new Date(lastEndTime),
        end: new Date(endRange),
        display: 'background',
        classNames: ['stripe-background', 'cursor-not-allowed', 'select-none'],
        editable: false,
        extendedProps: {
          eventType: 'unavailable',
        },
      });
    }

    return unavailableEvents;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);

    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
    } else {
      console.error('FullCalendar reference is null');
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    if (clickInfo.event.display === 'background' || clickInfo.event.extendedProps.eventType === 'booked') {
      return;
    }

    const eventElement = clickInfo.el;
    if (!eventElement) return;

    // Ottieni i valori di displayStart e displayEnd dall'evento
    const { displayStart, displayEnd, eventType } = clickInfo.event.extendedProps;

    if (screenWidth < 1024) {
      const eventId = clickInfo.event.id;

      if (selectedEventId === eventId) {
        const selectInfo: DateSelectArg = {
          start: displayStart || clickInfo.event.start!,
          end: displayEnd || clickInfo.event.end!,
          startStr: (displayStart || clickInfo.event.start!).toISOString(),
          endStr: (displayEnd || clickInfo.event.end!).toISOString(),
          allDay: clickInfo.event.allDay,
          view: clickInfo.view,
          jsEvent: clickInfo.jsEvent,
        };

        console.log('Selected event nel calendario:', selectInfo);
        onDateSelect(selectInfo);
        setSelectedEventId(null);
        eventElement.classList.remove('active');
      } else {
        document.querySelectorAll('.fc-event.available-event').forEach((el) => el.classList.remove('active'));
        setSelectedEventId(eventId);
        eventElement.classList.add('active');
      }
    } else {
      const selectInfo: DateSelectArg = {
        start: displayStart || clickInfo.event.start!,
        end: displayEnd || clickInfo.event.end!,
        startStr: (displayStart || clickInfo.event.start!).toISOString(),
        endStr: (displayEnd || clickInfo.event.end!).toISOString(),
        allDay: clickInfo.event.allDay,
        view: clickInfo.view,
        jsEvent: clickInfo.jsEvent,
      };
      onDateSelect(selectInfo);
    }
  };

  const renderEventContent = (eventInfo) => {
    const { displayStart, displayEnd } = eventInfo.event.extendedProps;
    const displayStartTime = displayStart?.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    const displayEndTime = displayEnd?.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    if (eventInfo.event.extendedProps.eventType === 'booked') {
      // Se è la lezione in modifica, mostra un messaggio o stile diverso
      if (
        bookLessonData.modifing_lesson_id &&
        eventInfo.event.id.includes(bookLessonData.modifing_lesson_id.toString())
      ) {
        return (
          <div className="flex items-center border-2 border-yellow-500 justify-center w-full bg-yellow-400 h-full">
            <p className="text-black font-bold">Lezione in modifica</p>
          </div>
        );
      }
      // Altrimenti, mostra l'evento prenotato standard
      return (
        <div className="flex items-center justify-center w-full bg-fpcpink h-full">
          <p className="text-black font-bold">Lezione prenotata</p>
        </div>
      );
    }

    const isSelected = selectedEventId === eventInfo.event.id;
    if (eventInfo.event.extendedProps.eventType === 'available') {
      return (
        <div className={`available-event ${isSelected ? '' : ''}`}>
          <div className="font-bold flex justify-center">
            <div className='w-full'>
              {displayStartTime} - {displayEndTime}
            </div>
            {isSelected && <div className="absolute right-0 mr-4 mt-1 px-2 py-1 bg-fpcpink rounded-lg text-md font-bold text-fpcred">Conferma</div>}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {screenWidth < 1024 && <DaysSlider selectedDate={selectedDate} onDateChange={handleDateChange} />}

      {render && events.length > 0 && (
        <FullCalendar
          key={screenWidth < 1024 ? 'mobile' : 'desktop'}
          locale={itLocale}
          ref={calendarRef}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView={screenWidth < 1024 ? 'timeGridDay' : 'timeGridWeek'}
          initialDate={selectedDate}
          headerToolbar={{
            start: 'title',
            center: '',
            end: screenWidth >= 1024 ? 'prev,today,next' : 'prev,next',
          }}
          eventDidMount={(info) => {
            if (
              info.event.extendedProps.eventType === 'booked' &&
              bookLessonData.modifing_lesson_id &&
              info.event.id.includes(bookLessonData.modifing_lesson_id.toString())
            ) {
              // Usa setTimeout per aspettare che il calcolo delle posizioni sia completato
              setTimeout(() => {
                // Prova a modificare il contenitore dell'evento
                const harness = info.el.closest('.fc-timegrid-event-harness');
                if (harness) {
                  (harness as HTMLElement).style.setProperty('left', '0%', 'important');
                  (harness as HTMLElement).style.setProperty('right', '0%', 'important');
                  (harness as HTMLElement).style.setProperty('width', '100%', 'important');
                  (harness as HTMLElement).style.setProperty('z-index', '1', 'important');
                } else {
                  info.el.style.setProperty('left', '0%', 'important');
                  info.el.style.setProperty('right', '0%', 'important');
                  info.el.style.setProperty('width', '100%', 'important');
                  info.el.style.setProperty('z-index', '1', 'important');
                }
              }, 0);
            }
          }}
          customButtons={{
            prev: {
              text: 'prev',
              click: function () {
                const calendarApi = calendarRef.current?.getApi();
                if (calendarApi) {
                  const currentDate = calendarApi.getDate();
                  const newDate = new Date(currentDate);
                  newDate.setDate(currentDate.getDate() - 1);

                  // Se la nuova data è precedente a oggi, non fare nulla
                  const todayWithoutTime = new Date();
                  todayWithoutTime.setHours(0, 0, 0, 0);

                  if (newDate >= todayWithoutTime) {
                    calendarApi.prev();
                    setSelectedDate(newDate);
                  }
                }
              },
            },
          }}
          dayCellClassNames={(arg) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return arg.date < today ? 'fc-day-disabled' : '';
          }}
          titleFormat={{ month: 'long' }}
          dayHeaderContent={
            screenWidth >= 1024
              ? (args) => {
                  const date = args.date;
                  const dayNumber = date.getDate();
                  const weekdayName = date.toLocaleDateString(undefined, { weekday: 'short' });
                  return (
                    <div className="custom-day-header p-2 rounded-full">
                      <div className="text-xl">{dayNumber}</div>
                      <div>{weekdayName}</div>
                    </div>
                  );
                }
              : false
          }
          editable={false}
          selectable={true}
          selectAllow={(selectInfo) => {
            const calendarApi = calendarRef.current?.getApi();
            if (calendarApi) {
              const events = calendarApi.getEvents();
              for (let event of events) {
                if (event.extendedProps.eventType === 'unavailable' || event.extendedProps.eventType === 'booked') {
                  if (event.start < selectInfo.end && selectInfo.start < event.end) {
                    return false;
                  }
                }
              }
              return true;
            }
            return false;
          }}
          selectMirror={true}
          weekends={true}
          validRange={{ start: start, end: maxDate }}
          events={events}
          eventClick={handleEventClick}
          dateClick={(info) => {
            setSelectedDate(info.date);
            setSelectedEventId(null);
          }}
          eventContent={renderEventContent}
          allDaySlot={false}
          height={'100%'}
          slotMinTime={'08:00:00'}
          slotMaxTime={'24:00:00'}
          scrollTime={'12:30:00'}
          slotDuration="00:15:00"
          scrollTimeReset={false}
          datesSet={(dateInfo) => {
            const newDate = new Date(dateInfo.start);
            if (newDate.toDateString() !== selectedDate.toDateString()) {
              setSelectedDate(newDate);
            }

            // Disabilita il pulsante prev se la data visualizzata è oggi
            if (calendarRef.current) {
              const calendarApi = calendarRef.current.getApi();
              const currentDate = calendarApi.getDate();
              const todayWithoutTime = new Date();
              todayWithoutTime.setHours(0, 0, 0, 0);

              // Trova il pulsante prev e aggiunge/rimuove la classe disabled
              const prevButton = document.querySelector('.fc-prev-button');
              if (prevButton) {
                if (currentDate.getTime() <= todayWithoutTime.getTime()) {
                  prevButton.classList.add('disabled');
                } else {
                  prevButton.classList.remove('disabled');
                }
              }
            }
          }}
        />
      )}
    </>
  );
};

export default MyCalendar;
