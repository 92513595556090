import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';
import { EventInput } from '@fullcalendar/core';
import Calendario from './Calendar';
import { BookLessonData } from '../model/BookLessonData';
import { BadgeEuro, Calendar, Clock, MapPin, User, ArrowBigLeft, BookOpen } from 'lucide-react';
import { motion } from 'framer-motion';
import { ReactComponent as Avatar } from '../ui/Avatar.svg';
import { TeacherInfo } from '../model/TeacherInfo';

interface EditBookedLessonProps {
  event: EventInput;
  closeModal: () => void;
}

type Steps = 'selectTeacher' | 'selectDate' | 'selectAvailableTeacher' | 'confirmFavoriteTeacher';

const EditBookedLesson: React.FC<EditBookedLessonProps> = ({ event, closeModal }) => {
  const lessonStart = event.start as Date;
  const lessonEnd = event.end as Date;
  const modifying_lesson_id = Number(event.id);

  // Calcolo della durata in ore
  const duration = (lessonEnd.getTime() - lessonStart.getTime()) / (1000 * 60 * 60);

  const bookLessonData: BookLessonData = {
    modifing_lesson_id: modifying_lesson_id,
    child: event.extendedProps.student,
    subject: event.extendedProps.subject,
    duration: duration,
    price: event.price,
    location: event.extendedProps.location,
    teacher: event.extendedProps.teacher, // Teacher preso dall'evento inizialmente
    date: {
      start: lessonStart,
      end: lessonEnd,
      startStr: lessonStart.toLocaleString(),
      endStr: lessonEnd.toLocaleString(),
      allDay: false,
      view: {} as any,
      jsEvent: {} as any,
    },
  };

  const [step, setStep] = useState<Steps>('selectTeacher');
  const [lessonData, setLessonData] = useState(bookLessonData);
  const [availableTeachers, setAvailableTeachers] = useState<any>({ exactAvailabilities: [], nearAvailabilities: [] });
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [originalTeacher, setOriginalTeacher] = useState(bookLessonData.teacher);
  const [teachersInfo, setTeachersInfo] = React.useState<TeacherInfo[]>([]);

  useEffect(() => {
    switch (step) {
      case 'selectTeacher':
        console.log('sono in selectTeacher');
        console.log('bookLessonData:', bookLessonData);
        break;
      case 'selectDate':
        console.log('sono in selectDate');
        console.log('LessonData passato al calendario:', lessonData);
        break;
      case 'selectAvailableTeacher':
        console.log('sono in selectAvailableTeacher');
        console.log('bookLessonData dopo il calendario:', lessonData);
        fetchAvailableTeachersForSelectedDate(lessonData.date.startStr, lessonData.date.endStr);
        break;
      case 'confirmFavoriteTeacher':
        console.log('Dati sono dentro favoriteTeacher:', lessonData);
        // Cerca disponibilità alternative per l'insegnante preferito
        fetchAvailableTeachersForSelectedDate(lessonData.date.startStr, lessonData.date.endStr);
        break;
    }
  }, [step]);

  function goBack() {
    switch (step) {
      case 'selectDate':
        setLessonData((prevData) => ({
          ...prevData,
          teacher: prevData.teacher || originalTeacher, // Ripristina l'insegnante originale se necessario
        }));
        setStep('selectTeacher');
        break;
      case 'confirmFavoriteTeacher':
        setStep('selectDate');
        break;
      case 'selectAvailableTeacher':
        setStep('selectDate');
        break;
    }
  }

  // step === 'selectTeacher'
  const selectCurrentTeacher = () => {
    setLessonData((prevData) => ({ ...prevData, teacher: bookLessonData.teacher }));
    setStep('selectDate');
  };

  const selectOtherTeachers = () => {
    setOriginalTeacher(lessonData.teacher);
    setLessonData((prevData) => ({ ...prevData, teacher: undefined })); // Nessuna preferenza
    setStep('selectDate');
  };

  const handleDateSelect = (selectInfo: any) => {
    const selectedDateStart = new Date(selectInfo.startStr);
    console.log('selectedDateStart:', selectedDateStart);
    const selectedDateEnd = new Date(selectInfo.startStr);
    console.log('selectedDateEnd:', selectedDateEnd);

    selectedDateEnd.setMinutes(selectedDateEnd.getMinutes() + bookLessonData.duration * 60);

    // Ensure the selected dates are in local time
    const selectedDateStartLocal = new Date(
      selectedDateStart.getTime() - selectedDateStart.getTimezoneOffset() * 60000
    );
    const selectedDateEndLocal = new Date(selectedDateEnd.getTime() - selectedDateEnd.getTimezoneOffset() * 60000);

    selectInfo.end = selectedDateEnd;
    selectInfo.endStr = selectedDateEndLocal.toISOString().slice(0, 19).replace('T', ' ');
    selectInfo.startStr = selectedDateStartLocal.toISOString().slice(0, 19).replace('T', ' ');

    setLessonData((prevData) => ({
      ...prevData,
      date: {
        start: selectedDateStart,
        end: selectedDateEnd,
        startStr: selectInfo.startStr,
        endStr: selectInfo.endStr,
        allDay: selectInfo.allDay,
        jsEvent: selectInfo.jsEvent,
        view: selectInfo.view,
      },
    }));
    setStep('selectAvailableTeacher');

    console.log('selectInfo: ', selectInfo);
    if (lessonData.teacher) {
      setStep('confirmFavoriteTeacher');
    } else {
      setStep('selectAvailableTeacher');
    }
  };

  const fetchAvailableTeachersForSelectedDate = async (startStr: string, endStr: string) => {
    setLoading(true);
    try {
      const availabilityRequest = {
        modifying_lesson_id: modifying_lesson_id,
        student_id: lessonData.child.id,
        teacher_id: lessonData.teacher ? lessonData.teacher.id : undefined,
        subject_id: lessonData.subject.id,
        location: lessonData.location,
        required_lessons: [{ start_date_time: startStr, end_date_time: endStr }],
      };

      const response = await api.post('availability/search', availabilityRequest);
      const availabilities = response.data.availabilities[0];
      console.log('availabilities: ', availabilities);

      // Ottieni le lezioni già prenotate per verificare sovrapposizioni
      const bookedLessonsResponse = await api.get(`/lesson`);
      const filteredLessons = (bookedLessonsResponse.data?.lessons || []).filter((lesson) => {
        return lesson.status !== 'deleted' && lesson.status !== 'placeholder';
      });

      // Filtra le near_availabilities per rimuovere quelle che si sovrappongono a lezioni esistenti
      const filteredNearAvailabilities = availabilities.near_availabilities.filter((availability: any) => {
        const availabilityStart = new Date(availability.start_date_time);
        const availabilityEnd = new Date(availability.end_date_time);

        // Controlla se questa disponibilità si sovrappone a qualsiasi lezione prenotata
        return !filteredLessons.some((lesson: any) => {
          const lessonStart = new Date(lesson.start_time);
          const lessonEnd = new Date(lesson.end_time);

          // Verifica sovrapposizione: se l'inizio della disponibilità è prima della fine della lezione
          // E la fine della disponibilità è dopo l'inizio della lezione
          return (
            (availabilityStart < lessonEnd && availabilityEnd > lessonStart) ||
            (lessonStart < availabilityEnd && lessonEnd > availabilityStart)
          );
        });
      });

      // Aggiorna le near_availabilities con quelle filtrate
      availabilities.near_availabilities = filteredNearAvailabilities;

      // Combina le disponibilità esatte e vicine
      const allAvailabilities = [...availabilities.exact_availabilities, ...availabilities.near_availabilities];

      // Trova gli ID degli insegnanti unici
      const uniqueTeacherIdsSet = new Set(allAvailabilities.map((availability: any) => availability.teacher_id));
      const uniqueTeacherIds = Array.from(uniqueTeacherIdsSet);

      // Ottieni le informazioni di tutti gli insegnanti necessari direttamente dall'API
      const teacherInfosPromises = uniqueTeacherIds.map(async (teacherId) => {
        const response = await api.get(`family/teacher/${teacherId}`);
        return response.data.teacher;
      });

      const fetchedTeacherInfos = await Promise.all(teacherInfosPromises);

      // Aggiorna lo stato con le informazioni degli insegnanti
      setTeachersInfo(fetchedTeacherInfos);

      // Imposta gli insegnanti disponibili
      setAvailableTeachers({
        exactAvailabilities: availabilities.exact_availabilities,
        nearAvailabilities: availabilities.near_availabilities,
      });
    } catch (error) {
      console.error(error);
      setError(true, error.response.data.message, error.response.status);
    } finally {
      setLoading(false);
    }
  };

  const confirmLessonUpdate = async (selectedTeacher: any, availability?: any) => {
    closeModal();
    setLoading(true);
    try {
      const updatedLessonData = {
        ...lessonData,
        teacher: selectedTeacher,
      };
      setLessonData(updatedLessonData);

      // Se è stata selezionata una disponibilità vicina, usa i suoi orari
      let startDateTime = updatedLessonData.date.startStr;
      let size = updatedLessonData.duration;

      if (availability) {
        // Usa l'orario della disponibilità selezionata
        startDateTime = availability.start_date_time;

        // Calcola la durata in ore dalla disponibilità selezionata
        const availabilityStart = new Date(availability.start_date_time);
        const availabilityEnd = new Date(availability.end_date_time);
        size = (availabilityEnd.getTime() - availabilityStart.getTime()) / (1000 * 60 * 60);
      }

      const payload = {
        start_date_time: startDateTime,
        location: updatedLessonData.location,
        subject_id: updatedLessonData.subject.id,
        teacher_id: updatedLessonData.teacher ? updatedLessonData.teacher.id : undefined,
        student_id: updatedLessonData.child.id,
        size: size,
      };
      console.log('payload per la modifica:', payload);
      const response = await api.put(`lesson/single/${event.id}`, payload);
      console.log('Lesson updated successfully:', response.data);
      // Aggiungi qui eventuali azioni post-modifica, come una notifica di successo o il reindirizzamento
    } catch (error) {
      console.error(error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status);
    } finally {
      setLoading(false);
      //window.location.reload();
    }
  };

  function TeacherCard({ teacher, availability, isNearAvailability = false }) {
    console.log('teacher: ', teacher);
    return (
      <div className="flex flex-col border-2 rounded-lg p-4 bg-white mb-4 hover:shadow-lg hover:border-fpcred transition-all duration-300 transform hover:-translate-y-1">
        <div className="flex gap-4">
          {/* Avatar container con dimensioni fisse */}
          <div className="flex-shrink-0 w-24 h-24 md:w-32 md:h-32 flex items-center justify-center bg-gray-50 rounded-lg">
            <User className="w-16 h-16 md:w-20 md:h-20 text-gray-500" />
          </div>

          {/* Contenuto della card */}
          <div className="flex flex-col flex-grow">
            {/* Nome insegnante */}
            <div className="flex flex-row gap-2 mb-2">
              <p className="text-xl font-semibold text-black">{teacher.first_name}</p>
              <p className="text-xl font-semibold text-black">{teacher.last_name}</p>
            </div>

            {/* Badge orario per disponibilità vicine */}
            {isNearAvailability && (
              <div className="inline-flex items-center bg-[#f3e8e8] text-[#d10c33] font-bold px-4 py-1 rounded-full text-sm mb-2 self-start">
                <Clock className="w-4 h-4 mr-1" />
                {new Date(availability.start_date_time).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                {' / '}
                {new Date(availability.end_date_time).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </div>
            )}

            {/* Bio con altezza fissa e overflow */}
            <div className="mb-4 h-20 overflow-y-auto">
              <p className="text-base text-gray-700">{teacher.bio ? teacher.bio : 'Nessuna bio disponibile'}</p>
            </div>

            {/* Pulsante prenota */}
            <div className="mt-auto">
              <button
                className="bg-[#d10c33] px-4 py-2 rounded-xl text-white hover:bg-[#d10c33]/90 transition-all duration-300 shadow-sm hover:shadow-md flex items-center"
                onClick={() =>
                  isNearAvailability ? confirmLessonUpdate(teacher, availability) : confirmLessonUpdate(teacher)
                }>
                <Calendar className="w-4 h-4 mr-2" />
                Conferma modifica
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full overflow-auto px-2 max-w-6xl mx-auto">
      <div className="absolute top-6 left-4">
        {step !== 'selectTeacher' && (
          <div className="w-10 h-10">
            <ArrowBigLeft className="cursor-pointer w-full h-full text-fpcred" onClick={goBack} />
          </div>
        )}
      </div>

      <div className="bg-fpcwhite p-4 rounded-lg shadow mb-2">
        <div className="flex flex-col gap-y-2">
          <h2 className="text-lg sm:text-xl font-bold text-[#d10c33]">Lezione prenotata</h2>
          <div className="flex gap-2 justify-between md:justify-start">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <BookOpen className="mr-2 h-5 w-5 text-[#d10c33]" />
                <span>{lessonData.subject.name}</span>
              </div>
              <div className="flex items-center gap-2">
                <MapPin className="mr-2 h-5 w-5 text-[#d10c33]" />
                <span>{lessonData.location}</span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <Calendar className="mr-2 h-5 w-5 text-[#d10c33]" />
                <span>{lessonStart.toLocaleDateString()}</span>
              </div>
              <div className="flex items-center gap-2">
                <Clock className="mr-2 h-5 w-5 text-[#d10c33]" />
                <span>
                  {lessonStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} -{' '}
                  {lessonEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {step === 'selectTeacher' && (
        <div>
          <h3 className="text-xl mb-2">Seleziona un'opzione per l'insegnante</h3>
          <div className="flex flex-col gap-4">
            <button
              onClick={selectCurrentTeacher}
              className="p-3 bg-green-500 text-white rounded-lg hover:bg-green-600">
              Mantieni insegnante attuale ({lessonData.teacher.first_name} {lessonData.teacher.last_name})
            </button>
            <button onClick={selectOtherTeachers} className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
              Nessuna preferenza
            </button>
          </div>
        </div>
      )}

      {step === 'selectDate' && (
        <div className="flex flex-col h-full">
          <Calendario bookLessonData={lessonData} onDateSelect={handleDateSelect} />
        </div>
      )}

      {step === 'confirmFavoriteTeacher' && (
        <div className="w-full">
          {/* Riepilogo Lezione e Insegnante Preferito - sticky */}
          <div className="">
            <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
              <div className="bg-fpcwhite rounded-lg p-4 md:p-6 shadow-md flex gap-4">
                <div className="flex-1">
                  <h2 className="text-lg sm:text-xl font-bold text-[#d10c33] mb-4">Riepilogo modifica</h2>
                  <div className="space-y-3">
                    <div className="flex items-center">
                      <Calendar className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{lessonData.date.start.toLocaleDateString('it-IT')}</span>
                      <span className="ml-2">
                        {lessonData.date.start
                          .toLocaleDateString('it-IT', { weekday: 'long' })
                          .charAt(0)
                          .toUpperCase() +
                          lessonData.date.start.toLocaleDateString('it-IT', { weekday: 'long' }).slice(1)}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>
                        {lessonData.date.start.toLocaleTimeString('it-IT', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                        /
                        {lessonData.date.end.toLocaleTimeString('it-IT', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <MapPin className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{lessonData.location}</span>
                    </div>
                    <div className="flex items-center">
                      <BadgeEuro className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{lessonData.price}</span>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <h2 className="text-lg sm:text-xl font-bold text-[#d10c33] mb-4">Insegnante Preferito</h2>
                  <div className="flex flex-col items-center gap-y-2">
                    <div className="flex items-center gap-2 w-full">
                      <Avatar />
                      <div className="flex flex-col md:flex-row gap-2">
                        <p className="text-lg sm:text-xl font-semibold">{lessonData.teacher.first_name}</p>
                        <p className="text-lg sm:text-xl font-semibold">{lessonData.teacher.last_name}</p>
                      </div>
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        className="bg-[#d10c33] px-4 py-2 rounded-xl text-white hover:bg-[#d10c33]/90 transition-colors duration-300"
                        onClick={() => confirmLessonUpdate(lessonData.teacher, null)}>
                        Conferma modifica
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Disponibilità alternative dell'insegnante preferito */}
          {availableTeachers.nearAvailabilities.length > 0 && (
            <div className="mt-6">
              <h2 className="text-lg sm:text-xl font-bold text-[#d10c33] mb-4">
                Disponibilità alternative dell'insegnante
              </h2>
              <div className="relative">
                <div className="flex overflow-x-auto py-3 px-2 gap-4 snap-x snap-mandatory scroll-smooth select-none cursor-grab active:cursor-grabbing">
                  {availableTeachers.nearAvailabilities
                    .filter((availability) => availability.teacher_id === lessonData.teacher.id)
                    .map((availability, index) => (
                      <div key={index} className="snap-start flex-shrink-0">
                        <TeacherCard teacher={lessonData.teacher} availability={availability} isNearAvailability />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {step === 'selectAvailableTeacher' && availableTeachers && (
        <div className="w-full flex flex-col gap-y-2">
          <div className="">
            <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
              <div className="bg-fpcwhite rounded-lg p-4 md:p-6 shadow-md flex gap-4">
                <div className="flex-1">
                  <h2 className="text-lg sm:text-xl font-bold text-[#d10c33] mb-4">Riepilogo modifica</h2>
                  <div className="flex justify-around md:justify-start md:gap-8">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <Calendar className="mr-2 h-5 w-5 text-[#d10c33]" />
                        <span>{lessonData.date.start.toLocaleDateString('it-IT')}</span>
                        <span className="ml-2">
                          {lessonData.date.start
                            .toLocaleDateString('it-IT', { weekday: 'long' })
                            .charAt(0)
                            .toUpperCase() +
                            lessonData.date.start.toLocaleDateString('it-IT', { weekday: 'long' }).slice(1)}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <Clock className="mr-2 h-5 w-5 text-[#d10c33]" />
                        <span>
                          {lessonData.date.start.toLocaleTimeString('it-IT', {
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                          /
                          {lessonData.date.end.toLocaleTimeString('it-IT', {
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <MapPin className="mr-2 h-5 w-5 text-[#d10c33]" />
                        <span>{lessonData.location}</span>
                      </div>
                      <div className="flex items-center">
                        <BadgeEuro className="mr-2 h-5 w-5 text-[#d10c33]" />
                        <span>{lessonData.price}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="bg-fpcwhite w-full h-full rounded-lg p-2 md:p-6 shadow-md">
            <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Insegnanti Disponibili</h2>
            <div className="flex flex-col h-full">
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Disponibilità per l'orario richiesto</h3>
                <div className="relative">
                  <div className="flex overflow-x-auto py-4 px-2 gap-4 snap-x snap-mandatory scroll-smooth select-none cursor-grab active:cursor-grabbing">
                    {availableTeachers.exactAvailabilities.map((availability, index) => {
                      const teacher = teachersInfo.find((teacher) => teacher.id === availability.teacher_id);
                      return teacher ? (
                        <div key={index} className="snap-start flex-shrink-0">
                          <TeacherCard teacher={teacher} availability={availability} />
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>

              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Disponibilità vicino all'orario richiesto</h3>
                <div className="relative">
                  <div className="flex overflow-x-auto py-4 px-2 gap-4 snap-x snap-mandatory scroll-smooth select-none cursor-grab active:cursor-grabbing">
                    {availableTeachers.nearAvailabilities.map((availability, index) => {
                      const teacher = teachersInfo.find((teacher) => teacher.id === availability.teacher_id);
                      return teacher ? (
                        <div key={index} className="snap-start flex-shrink-0">
                          <TeacherCard teacher={teacher} availability={availability} isNearAvailability />
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditBookedLesson;
